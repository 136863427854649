.gm-style .gm-style-iw-d {
    overflow: hidden !important; 
}

.gm-style .gm-style-iw-c {
    @apply bg-base-300;
    padding: 12px !important;
}

  
.gm-style .gm-ui-hover-effect span {
    @apply bg-base-content;
}

  /* Adjust the arrow's position */
  .gm-style .gm-style-iw-tc:after {
    @apply bg-base-300;
  }
  